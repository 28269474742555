// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/codebuild/output/src874144957/src/acs/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/codebuild/output/src874144957/src/acs/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("/codebuild/output/src874144957/src/acs/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hello-js": () => import("/codebuild/output/src874144957/src/acs/src/pages/hello.js" /* webpackChunkName: "component---src-pages-hello-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src874144957/src/acs/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("/codebuild/output/src874144957/src/acs/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src874144957/src/acs/.cache/data.json")

